<template>
  
  <router-view/>

  <!-- MOBILE NAV -->
            <div class="relative true pt-11 pb-6 px-5 lg:pt-[6.5rem] lg:px-7.5 lg:pb-12 xl:px-10">

                <!-- MOBILE NAV -->
                <div class="fixed top-0 left-0 z-50 w-full bg-n-8 border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm hidden" id="mobileBox">
                    <div class="flex items-center h-[4.75rem] px-5 lg:h-[5.25rem] lg:px-7.5 xl:px-10">
                        <a class="block w-[11.875rem] xl:mr-8" href="https://www.navox.io">
                            <img alt="Brainwave" fetchpriority="high" width="190" height="40" decoding="async" data-nimg="1" src="@/assets/img/logo_long.png" style="color: transparent;">
                        </a>
                        <nav class="flex fixed top-[4.8125rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent">
                            <div class="relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row">

                                <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1  px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io" style="font-family: 'Neue Machina';">
                                    Home<div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                    <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                </a>

                                <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1  px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io/about" style="font-family: 'Neue Machina';">
                                    About<div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                    <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                </a>
                                <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1  px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io/staking" style="font-family: 'Neue Machina';">
                                    Staking<div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                    <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                </a>
                                <a class="block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1  px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold lg:text-n-1/50 lg:leading-5 lg:hover:text-n-1 xl:px-12" href="https://www.navox.io/contact" style="font-family: 'Neue Machina';">
                                    Contact<div class="hidden absolute left-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                    <div class="hidden absolute right-0 bottom-0 w-0.25 h-1.5 bg-n-6 lg:block "></div>
                                </a>

                                <router-link class="button relative inline-flex items-center justify-center h-11 px-7 text-n-8 transition-colors hover:text-color-1" :to="{name: 'sign-in'}" style="width: 200px; margin-top: 20px;">
                                    <span class="relative z-10" style="font-family: 'Montserrat Alternates'; font-weight: 900; color: black;">Sign in</span>
                                    <svg class="absolute top-0 left-0" width="21" height="44" viewBox="0 0 21 44">
                                        <path fill="#ffffff" stroke="white" stroke-width="2" d="M21,43.00005 L8.11111,43.00005 C4.18375,43.00005 1,39.58105 1,35.36365 L1,8.63637 C1,4.41892 4.18375,1 8.11111,1 L21,1"></path>
                                    </svg>
                                    <svg class="absolute top-0 left-[1.3125rem] w-[calc(100%-2.625rem)]" height="44" viewBox="0 0 100 44" preserveAspectRatio="none" fill="#fff">
                                        <polygon fill="#fff"   fill-rule="nonzero" points="100 0 100 44 0 44 0 0"></polygon>
                                    </svg>
                                    <svg class="absolute top-0 right-0" width="21" height="44" viewBox="0 0 21 44">
                                        <path fill="#fff" stroke="white" stroke-width="2" d="M0,43.00005 L5.028,43.00005 L12.24,43.00005 C16.526,43.00005 20,39.58105 20,35.36365 L20,16.85855 C20,14.59295 18.978,12.44425 17.209,10.99335 L7.187,2.77111 C5.792,1.62675 4.034,1 2.217,1 L0,1"></path>
                                    </svg>
                                </router-link>  
                                
                            </div>

                            <!-- bg image -->
                            <div class="absolute inset-0 pointer-events-none">
                                <div class="absolute inset-0 opacity-[.03]">
                                    <!-- <img alt="Background" loading="lazy" width="688" height="953" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 w-full h-full object-cover" srcset="/_next/image?url=%2Fimages%2Fheader%2Fbackground.jpg&amp;w=750&amp;q=75 1x, /_next/image?url=%2Fimages%2Fheader%2Fbackground.jpg&amp;w=1920&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Fheader%2Fbackground.jpg&amp;w=1920&amp;q=75" style="color: transparent;"> -->

                                    <img alt="Background" loading="lazy" width="688" height="953" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity opacity-100 w-full h-full object-cover" src="@/assets/img/background.webp">

                                </div>
                                <div class="absolute top-1/2 left-1/2 w-[51.375rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2">
                                    <div class="absolute top-1/2 left-1/2 w-[36.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                                    <div class="absolute top-1/2 left-1/2 w-[23.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                                </div>
                                <div class="absolute top-0 left-5 w-0.25 h-full bg-n-6"></div>
                                <div class="absolute top-0 right-5 w-0.25 h-full bg-n-6"></div>
                                <div class="absolute top-[4.4rem] left-16 w-3 h-3 bg-gradient-to-b from-[#DD734F] to-[#1A1A32] rounded-full"></div>
                                <div class="absolute top-[12.6rem] right-16 w-3 h-3 bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full"></div>
                                <div class="absolute top-[26.8rem] left-12 w-6 h-6 bg-gradient-to-b from-[#88E5BE] to-[#1A1A32] rounded-full"></div>
                            </div>
                        </nav>
                        
                       
                        <!-- CLOSE BURGER -->
                        <button class="button relative inline-flex items-center justify-center h-11 px-3 text-n-1 transition-colors hover:text-color-1 ml-auto lg:hidden" fdprocessedid="cskby" id="navClose">
                            <span class="relative z-10">
                                <svg class="overflow-visible" width="20" height="12" viewBox="0 0 20 12">
                                    <rect class="transition-all origin-center" y="5" width="20" height="2" rx="1" fill="white" transform="rotate(45)"></rect>
                                    <rect class="transition-all origin-center" y="5" width="20" height="2" rx="1" fill="white" transform="rotate(-45)"></rect>
                                </svg>
                            </span>
                            <svg class="absolute top-0 left-0" width="21" height="44" viewBox="0 0 21 44">
                                <path fill="none" stroke="#00ff00" stroke-width="2" d="M21,43.00005 L8.11111,43.00005 C4.18375,43.00005 1,39.58105 1,35.36365 L1,8.63637 C1,4.41892 4.18375,1 8.11111,1 L21,1"></path>
                            </svg>
                            <svg class="absolute top-0 left-[1.3125rem] w-[calc(100%-2.625rem)]" height="44" viewBox="0 0 100 44" preserveAspectRatio="none" fill="none">
                                <polygon fill="url(#btn-top)" fill-rule="nonzero" points="100 42 100 44 0 44 0 42"></polygon>
                                <polygon fill="url(#btn-bottom)" fill-rule="nonzero" points="100 0 100 2 0 2 0 0"></polygon>
                            </svg>
                            <svg class="absolute top-0 right-0" width="21" height="44" viewBox="0 0 21 44">
                                <path fill="none" stroke="#00ff00" stroke-width="2" d="M0,43.00005 L5.028,43.00005 L12.24,43.00005 C16.526,43.00005 20,39.58105 20,35.36365 L20,16.85855 C20,14.59295 18.978,12.44425 17.209,10.99335 L7.187,2.77111 C5.792,1.62675 4.034,1 2.217,1 L0,1"></path>
                            </svg>
                        </button>
                    </div>
                </div>

              
                <!-- FOOTER -->
                <div class="lg:flex lg:items-center lg:justify-between">

                    <div class="hidden caption text-n-4 lg:block" style="padding-left: 20px; padding-right: 20px; font-size: 14px;">

                        Navox is a crypto platform and although we conduct due diligence on our blockchain assets and digital currencies, You are fully and solely responsible for evaluating your investments. In many cases, blockchain assets you exchange on the basis of your research may not increase in value, and may decrease in value. Similarly, blockchain assets you exchange on the basis of your research may increase in value after your exchange. <br> <br>

                        Past performance is not indicative of future results. Any investment in blockchain assets involves the risk of loss of part or all of your investment. The value of the blockchain assets you exchange is subject to market and other investment risks.

                    </div>

                </div>

                <div class="lg:flex lg:items-center lg:justify-between">
                    
                    <div class="hidden caption text-n-4 lg:block" style="width: 100%; text-align: center;">  <br><br> © 2023 Navox. All Rights Reserved</div>
                    <!-- <div class="flex justify-center -mx-4">
                        <a class="flex items-center justify-center w-10 h-10 mx-4 bg-n-7 rounded-full transition-colors hover:bg-n-6" href="#" target="_blank">
                            <img alt="Discord" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity false undefined" src="/images/socials/discord.svg" style="color: transparent"/>
                        </a>
                        <a class="flex items-center justify-center w-10 h-10 mx-4 bg-n-7 rounded-full transition-colors hover:bg-n-6" href="#" target="_blank">
                            <img alt="Twitter" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity false undefined" src="/images/socials/twitter.svg" style="color: transparent"/>
                        </a>
                        <a class="flex items-center justify-center w-10 h-10 mx-4 bg-n-7 rounded-full transition-colors hover:bg-n-6" href="#" target="_blank">
                            <img alt="Instagram" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity false undefined" src="/images/socials/instagram.svg" style="color: transparent"/>
                        </a>
                        <a class="flex items-center justify-center w-10 h-10 mx-4 bg-n-7 rounded-full transition-colors hover:bg-n-6" href="#" target="_blank">
                            <img alt="Telegram" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity false undefined" src="/images/socials/telegram.svg" style="color: transparent"/>
                        </a>
                        <a class="flex items-center justify-center w-10 h-10 mx-4 bg-n-7 rounded-full transition-colors hover:bg-n-6" href="#" target="_blank">
                            <img alt="Facebook" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" class="inline-block align-top opacity-0 transition-opacity false undefined" src="/images/socials/facebook.svg" style="color: transparent"/>
                        </a>
                    </div> -->
                </div>

                <div class="hidden absolute top-0 left-5 w-0.25 h-full bg-stroke-1 pointer-events-none md:block lg:left-7.5 xl:left-10"></div>
                <div class="hidden absolute top-0 right-5 w-0.25 h-full bg-stroke-1 pointer-events-none md:block lg:right-7.5 xl:right-10"></div>
                <div class="hidden absolute top-0 left-7.5 right-7.5 h-0.25 bg-stroke-1 undefined pointer-events-none lg:block xl:left-10 right-10"></div>
                <svg class="hidden absolute -top-[0.3125rem] left-[1.5625rem] undefined pointer-events-none lg:block xl:left-[2.1875rem]" xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none">
                    <path d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z" fill="#ada8c4"></path>
                </svg>
                <svg class="hidden absolute -top-[0.3125rem] right-[1.5625rem] undefined pointer-events-none lg:block xl:right-[2.1875rem]" xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none">
                    <path d="M7 1a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 1 1-1h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H8a1 1 0 0 1-1-1V1z" fill="#ada8c4"></path>
                </svg>
            </div>

  <svg class="block" width="0" height="0">
      <defs>
          <linearGradient id="btn-left" x1="50%" x2="50%" y1="0%" y2="100%">
               <stop offset="0%" stop-color="#89F9E8"></stop>
               <stop offset="100%" stop-color="#FACB7B"></stop>
          </linearGradient>
          <linearGradient id="btn-top" x1="100%" x2="0%" y1="50%" y2="50%">
              <stop offset="0%" stop-color="#D87CEE"></stop>
              <stop offset="100%" stop-color="#FACB7B"></stop>
          </linearGradient>
          <linearGradient id="btn-bottom" x1="100%" x2="0%" y1="50%" y2="50%">
              <stop offset="0%" stop-color="#9099FC"></stop>
              <stop offset="100%" stop-color="#89F9E8"></stop>
          </linearGradient>
          <linearGradient id="btn-right" x1="14.635%" x2="14.635%" y1="0%" y2="100%">
              <stop offset="0%" stop-color="#9099FC"></stop>
              <stop offset="100%" stop-color="#D87CEE"></stop>
          </linearGradient>
      </defs>
  </svg>

</template>

<style>

  @import url(./assets/css/base.css);
  @import url(./assets/css/gTranslate.css);

</style>




